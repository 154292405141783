import { default as _91form_93WSVZ1P8HL4Meta } from "/codebuild/output/src449811978/src/form.winx.ai/pages/[[research]]/[form].vue?macro=true";
import { default as denied01QszQLLYJMeta } from "/codebuild/output/src449811978/src/form.winx.ai/pages/[[research]]/denied.vue?macro=true";
import { default as finish8quvIetTwlMeta } from "/codebuild/output/src449811978/src/form.winx.ai/pages/[[research]]/finish.vue?macro=true";
import { default as startiJVhH3cWJfMeta } from "/codebuild/output/src449811978/src/form.winx.ai/pages/[[research]]/start.vue?macro=true";
import { default as _91_91research_93_93Jo7XIbUMYVMeta } from "/codebuild/output/src449811978/src/form.winx.ai/pages/[[research]].vue?macro=true";
import { default as indexEtlpBf8f9SMeta } from "/codebuild/output/src449811978/src/form.winx.ai/pages/index.vue?macro=true";
import { default as loginfVHlOhVIA5Meta } from "/codebuild/output/src449811978/src/form.winx.ai/pages/login.vue?macro=true";
import { default as redirectuJSMaw4JV9Meta } from "/codebuild/output/src449811978/src/form.winx.ai/pages/redirect.vue?macro=true";
export default [
  {
    name: _91_91research_93_93Jo7XIbUMYVMeta?.name ?? "research",
    path: _91_91research_93_93Jo7XIbUMYVMeta?.path ?? "/:research?",
    meta: _91_91research_93_93Jo7XIbUMYVMeta || {},
    alias: _91_91research_93_93Jo7XIbUMYVMeta?.alias || [],
    redirect: _91_91research_93_93Jo7XIbUMYVMeta?.redirect,
    component: () => import("/codebuild/output/src449811978/src/form.winx.ai/pages/[[research]].vue").then(m => m.default || m),
    children: [
  {
    name: _91form_93WSVZ1P8HL4Meta?.name ?? "research-form",
    path: _91form_93WSVZ1P8HL4Meta?.path ?? ":form()",
    meta: _91form_93WSVZ1P8HL4Meta || {},
    alias: _91form_93WSVZ1P8HL4Meta?.alias || [],
    redirect: _91form_93WSVZ1P8HL4Meta?.redirect,
    component: () => import("/codebuild/output/src449811978/src/form.winx.ai/pages/[[research]]/[form].vue").then(m => m.default || m)
  },
  {
    name: denied01QszQLLYJMeta?.name ?? "research-denied",
    path: denied01QszQLLYJMeta?.path ?? "denied",
    meta: denied01QszQLLYJMeta || {},
    alias: denied01QszQLLYJMeta?.alias || [],
    redirect: denied01QszQLLYJMeta?.redirect,
    component: () => import("/codebuild/output/src449811978/src/form.winx.ai/pages/[[research]]/denied.vue").then(m => m.default || m)
  },
  {
    name: finish8quvIetTwlMeta?.name ?? "research-finish",
    path: finish8quvIetTwlMeta?.path ?? "finish",
    meta: finish8quvIetTwlMeta || {},
    alias: finish8quvIetTwlMeta?.alias || [],
    redirect: finish8quvIetTwlMeta?.redirect,
    component: () => import("/codebuild/output/src449811978/src/form.winx.ai/pages/[[research]]/finish.vue").then(m => m.default || m)
  },
  {
    name: startiJVhH3cWJfMeta?.name ?? "research-start",
    path: startiJVhH3cWJfMeta?.path ?? "start",
    meta: startiJVhH3cWJfMeta || {},
    alias: startiJVhH3cWJfMeta?.alias || [],
    redirect: startiJVhH3cWJfMeta?.redirect,
    component: () => import("/codebuild/output/src449811978/src/form.winx.ai/pages/[[research]]/start.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexEtlpBf8f9SMeta?.name ?? "index",
    path: indexEtlpBf8f9SMeta?.path ?? "/",
    meta: indexEtlpBf8f9SMeta || {},
    alias: indexEtlpBf8f9SMeta?.alias || [],
    redirect: indexEtlpBf8f9SMeta?.redirect,
    component: () => import("/codebuild/output/src449811978/src/form.winx.ai/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginfVHlOhVIA5Meta?.name ?? "login",
    path: loginfVHlOhVIA5Meta?.path ?? "/login",
    meta: loginfVHlOhVIA5Meta || {},
    alias: loginfVHlOhVIA5Meta?.alias || [],
    redirect: loginfVHlOhVIA5Meta?.redirect,
    component: () => import("/codebuild/output/src449811978/src/form.winx.ai/pages/login.vue").then(m => m.default || m)
  },
  {
    name: redirectuJSMaw4JV9Meta?.name ?? "redirect",
    path: redirectuJSMaw4JV9Meta?.path ?? "/redirect",
    meta: redirectuJSMaw4JV9Meta || {},
    alias: redirectuJSMaw4JV9Meta?.alias || [],
    redirect: redirectuJSMaw4JV9Meta?.redirect,
    component: () => import("/codebuild/output/src449811978/src/form.winx.ai/pages/redirect.vue").then(m => m.default || m)
  }
]